import React from "react"

import Playlist from "../components/playlist"

import imgbanner from "../images/index/thumb/classics.jpg"
import allcategories from "../content/categorytitles.json"
import videolist from "../content/playlistclassics.json"


const ClassicsPage = ({location}) => (
  <Playlist
    location={location}
    headingClassName={"color-text-secondary"}
    path={"classics"}
    bannerimg={imgbanner}
    title={allcategories.classics.title}
    writeup={allcategories.classics.description} 
    videolist={videolist} />
)

export default ClassicsPage
